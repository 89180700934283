import optionData from "../ComboListBox/ComboListBox.interface";
import userData from '../../models/userModel';
import exchangeRate from "../../models/exchangeRate.model";
import config from '../../config.json';

class DashboardService {
    
    public productsList: optionData[] = [];
    public locationsList: optionData[] = [];
    public rateData: exchangeRate = {currency: 'EUR', rate: 0};
    private user: userData;
    private currency: string = 'HUF';

    public constructor(userData: userData, currency: string) {
        this.user = userData;
        this.currency = currency !== '' ? currency.toLowerCase() : 'huf';
        this.getLocationsList();
        this.getCurrencyRate();
    }

    public async getProductsList(currency: string, category: string) {
        return new Promise(resolve => {
            const request = {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            };
            fetch(config.endpoint + "bbus/productshotel?sortfield=t.label&sortorder=ASC&limit=100&mode=2&sqlfilters=ef.currency ILIKE '" + currency + "' AND ef.hotelsales=1 AND (ef.is_in_bundle IS NULL OR ef.is_in_bundle=0) AND ef.basic_service IN ('"+category+"','2')&DOLAPIKEY="+this.user.token,request)
            .then(async response => {
                if (response.status === 404) {
                    resolve([]);
                }
                const data = await response.json();
                if (Array.isArray(data)) {
                    data.forEach((item: any) => {
                        let sumPrice = 0;
                        let netPrice = 0;
                        if ('sub_products' in item && item.sub_products.length > 0) {
                            item.sub_products.forEach((sub_product: any) => {
                                sumPrice += currency === 'HUF' ? parseInt(sub_product.price_ttc) : parseFloat(sub_product.price_ttc);
                            });
                        }
                        else {
                            sumPrice = currency === 'HUF' ? parseInt(item.price_ttc) : parseFloat(item.price_ttc);
                            netPrice = currency === 'HUF' ? parseFloat(item.price) : parseFloat(item.price);
                        }
                        if (currency === 'HUF') {
                            sumPrice = Math.ceil(sumPrice / 5) * 5;
                        }
                        const product: optionData = {
                            image: 'bus.png', 
                            title: item.label, 
                            id: item.id,
                            adultPrice: sumPrice, 
                            childPrice: sumPrice, 
                            netAdultPrice: netPrice, 
                            netChildPrice: netPrice, 
                            tax: parseInt(item.tva_tx),
                            subtitle: item.description ? item.description : '', 
                            value: item.description ? item.description : item.label,
                            subproducts: item.sub_products
                        };
                        this.productsList.push(product);
                    });
                    resolve(this.productsList);
                }
                else {
                    resolve([]);
                }
            })
            .catch(error => {
                console.error('Error fetching products list:', error);
                resolve([]);
            });
        });
    }

    public getAvailableSpaces(categoryId: number, productId: number, dateFrom: string, dateTo: string, participantNumber: number) {
        const body = {
            type_id: categoryId, 
            date_from: dateFrom, 
            date_to: dateTo, 
            participant_number: participantNumber, 
            product_id: productId
        };
        return new Promise(resolve => {
            const request = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            };
            fetch(config.endpoint + "bookingapi/getavailablespaces?DOLAPIKEY="+this.user.token,request)
           .then(async response => {
                if (response.status === 404) {
                    resolve([]);
                }
                const data = await response.json();
                resolve(data);
            })
           .catch(error => {
                console.error('Error fetching available spaces:', error);
                resolve([]);
            });
        });
    }

    public getLocationsList() {
        const request = {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(config.endpoint + 'bbus/entities?DOLAPIKEY='+this.user.token,request)
        .then(async response => {
            const data = await response.json();
            data.forEach((item: any) => {
            const location: optionData = {
                image: '', 
                title: item.label, 
                subtitle: '', 
                value: item.label, 
                id: 0,
                adultPrice: 0, 
                childPrice: 0, 
                netAdultPrice: 0,
                netChildPrice: 0,
                tax: 0,
                subproducts: []
            };
            this.locationsList.push(location);
            });
            return this.locationsList;
        });
    }

    public getCurrencyRate() {
        const request = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(config.endpoint + 'bbus/config?DOLAPIKEY='+this.user.token,request)
        .then(async response => {
            const data = await response.json();
            this.rateData.rate = data.exchange_rate.EUR;
            localStorage.setItem('accounts', JSON.stringify(data.invoice.account_id));
            localStorage.setItem('currencies', JSON.stringify(data.invoice.currencies));
            localStorage.setItem('paymentsMode', JSON.stringify(data.invoice.payments_mode));
        });
    }

    public getTicketCategoriesList() {
        return new Promise(resolve => {
            const request = {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            };
            fetch(config.endpoint + "bbus/checkPermission?hotelsales=1&DOLAPIKEY="+this.user.token,request)
            .then(async response => {
                const data = await response.json();
                resolve(data);
            });    
        });
    }

    public prepareInvoice(data: any) {
        return new Promise(resolve => {
            const body = {
                type_id: parseInt(data.typeId),
                fk_event: parseInt(data.selectedTime),
                reservations: 1,
                product_id: parseInt(data.id),
                sendId: data.sendId
            };
            const request = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            };
            fetch(config.endpoint + "bookingapi/firsteventstep?DOLAPIKEY="+this.user.token,request)
           .then(async response => {
                if (response.status === 404) {
                    resolve([]);
                }
                const data = await response.json();
                resolve(data);
            })
           .catch(error => {
                console.error('Error preparing invoice:', error);
                resolve([]);
            });
        });
    }

    public removePreOrderItem(ids: number[]) {
        return new Promise(resolve => {
            const body = {
                preorders: ids
            };
            const request = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            };
            fetch(config.endpoint + "bookingapi/preorderEventErasuer?DOLAPIKEY="+this.user.token,request)
           .then(async response => {
                if (response.status === 404) {
                    resolve([]);
                }
                const data = await response.json();
                resolve(data);
            })
           .catch(error => {
                console.error('Error preparing invoice:', error);
                resolve([]);
            });
        });
    }
}

export default DashboardService;