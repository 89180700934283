import './Cart.scss';
import { useState, useEffect } from 'react';
import ProductModel from '../../models/product.model';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import config from '../../config.json';
import DashboardService from '../Dashboard/DashboardService';
import userData from '../../models/userModel';

const Cart = (props: any) => {
    const [items, setItems] = useState<ProductModel[]>([]);
    const [sum, setSum] = useState(0);
    const [sumEUR, setSumEUR] = useState(0);
    const [preordersList, setPreordersList] = useState<number[]>();
    const storedUserData: any = localStorage.getItem('userData');
    const [user, setUser] = useState<userData>(JSON.parse(storedUserData) as userData);
    const [currency, setCurrency] = useState('');

    const dashboardService = new DashboardService(user,currency);

    const decrementAdultQNT = (id: number) => {
        let prevList: number[];
        let updatedPrevList: number[];
        let itemPrevList: number[];
        let updatedItem: ProductModel[];
        if (items[id].adult > 1) {
            if (items[id].typeId === '5') {
                prevList = preordersList || [];
                itemPrevList = items[id].preorders || [];
                updatedItem = items;
                const lastPrevItem = itemPrevList.pop();
                if (lastPrevItem !== undefined) {
                    dashboardService.removePreOrderItem([lastPrevItem]).then((deleteResponse: any) => {
                        console.log(deleteResponse);
                    });
                    let updatedPrevList = prevList.filter(num => num !== lastPrevItem);
                    setPreordersList(updatedPrevList);
                    props.updatePreordersList(updatedPrevList);
                }
                updatedItem[id].preorders = itemPrevList !== undefined ? itemPrevList : [];
            }
            const newAdult = items[id].adult-1;
            const newAdultPrice = newAdult * items[id].adultPrice;
            const updatedCart = items.map((row,index) => {
                if (index === id) {
                    return {...row, adult: newAdult, adultPrice: items[id].adultPrice, sumPrice: newAdultPrice, preorders: itemPrevList };
                }
                return row;
            });
            setItems(updatedCart);
            props.callbackHandler(updatedCart);
        }
    };

    const incrementAdultQNT = (id: number) => {
        let nextID: number;
        let prevList: number[];
        let itemPrevList: number[];
        if (items[id].typeId === '5') {
            dashboardService.prepareInvoice(items[id]).then((preparedData: any) => {
                nextID = parseInt(preparedData[0].id);
                prevList = preordersList || [];
                itemPrevList = items[id].preorders || [];
                prevList.push(nextID);
                itemPrevList.push(nextID);
                setPreordersList(prevList => [...(prevList || []), nextID]);
                props.updatePreordersList(preordersList);
            });
        }
        const newAdult = items[id].adult+1;
        const newAdultPrice = newAdult * items[id].adultPrice;
        const updatedCart = items.map((row,index) => {
            if (index === id) {
                return {...row, adult: newAdult, adultPrice: items[id].adultPrice, sumPrice: newAdultPrice, preorders: itemPrevList };
            }
            return row;
        });
        setItems(updatedCart);
        props.callbackHandler(updatedCart);
    };

    const decrementChildQNT = (id: number) => {
        if (items[id].child > 1) {
            const newChild = items[id].child-1;
            const newChildPrice = newChild * items[id].netChildPrice;
            const updatedCart = items.map((row,index) => {
                if (index === id) {
                    return {...row, child: newChild, childPrice: newChildPrice, sumPrice: items[id].adultPrice+newChildPrice };
                }
                return row;
            });
            setItems(updatedCart);
            props.callbackHandler(updatedCart);
        }
    };

    const incrementChildQNT = (id: number) => {
        const newChild = items[id].child+1;
        const newChildPrice = newChild * items[id].netChildPrice;
        const updatedCart = items.map((row,index) => {
            if (index === id) {
                return {...row, child: newChild, childPrice: newChildPrice, sumPrice: items[id].adultPrice+newChildPrice };
            }
            return row;
        });
        setItems(updatedCart);
        props.callbackHandler(updatedCart);
    };

    const deleteItemFromCart = (id: number) => {
        let itemPrevList: number[];
        if (items[id].typeId === '5') {
            itemPrevList = items[id].preorders || [];
            dashboardService.removePreOrderItem(itemPrevList).then((deleteResponse: any) => {
                console.log(deleteResponse);
            });
            if (preordersList !== undefined) {
                let updatedPrevList = preordersList.filter(num => !itemPrevList.includes(num));
                setPreordersList(updatedPrevList);
                props.updatePreordersList(updatedPrevList);
            }
        }
        var itemsList = [...items];
        itemsList.splice(id,1);
        setItems(itemsList);
        props.callbackHandler(itemsList);
    };

    useEffect(() => {
        setItems(props.cart);
        setPreordersList(props.preordersList);
    },[props]);

    useEffect(() => {
        let bigsum = 0;
        let bigsumeur = 0;
        items.forEach((row) => {
            bigsum += row.sumPrice;
            bigsumeur += Math.round(((row.sumPrice / row.exchangeRate)*100))/100;
        });
        setSum(bigsum);
        setSumEUR(bigsumeur);
    },[items]);

    const list = items.map((item: any, index: number) => {
        return(
            <div className="qntSum" key={index}>
                <div className="cartProduct">
                    {item.product} <FontAwesomeIcon className="trashIcon" icon={faTrash} onClick={ () => deleteItemFromCart(index)} title="Delete" />
                    <br />
                    {item.selectedDate !== '' && 
                        <span>Date: {item.selectedDate}</span>
                    }
                </div>
                <table className="qntTable">
                    <tbody>
                        <tr>
                            <td className="qntInfo">
                                <strong>Persons</strong><br />
                                {props.currency === 'EUR' ? item.adultPrice.toFixed(2) : item.adultPrice} {props.currency === 'EUR' ? config.currencySigns.EUR : config.currencySigns.HUF} / person
                            </td>
                            <td className="qntControll">
                                <button className="qntButton" onClick={ () => decrementAdultQNT(index) }>-</button>
                                <small className="qntCounter">{item.adult}</small>
                                <button className="qntButton" onClick={ () => incrementAdultQNT(index) }>+</button><br />
                                {props.currency === 'EUR' ? item.sumPrice.toFixed(2) : item.sumPrice} {props.currency === 'EUR' ? config.currencySigns.EUR : config.currencySigns.HUF}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    });

    return(
        <div className="cartContainer">
            { items.length > 0 && 
            <label className="formLabel firstLabel">Cart</label>
            }
            {list}
            { sum > 0 && items.length > 0 && 
                <div className="qntSum">
                    <h5>Total payable:</h5>
                    {props.currency === 'EUR' ? sum.toFixed(2) : sum} {props.currency === 'EUR' ? config.currencySigns.EUR : config.currencySigns.HUF}<br />
                </div>
            }
        </div>
    );
};

//<small>(Exchange rate: 1 EUR = {items[0].exchangeRate} HUF)</small>
export default Cart;