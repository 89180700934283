import { Row, Col, Container, Navbar, NavbarBrand, Alert, Card } from "react-bootstrap";
import ComboListBox from "../ComboListBox/ComboListBox";
import QuantityBox from "../QuantityBox/QuantityBox";
import './dashboard.scss';
import { useState, useEffect } from 'react';
import optionData from "../ComboListBox/ComboListBox.interface";
import ProductModel from "../../models/product.model";
import Cart from "../Cart/Cart";
import Order from '../Order/Order';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import userData from "../../models/userModel";
import exchangeRate from "../../models/exchangeRate.model";
import DashboardService from "./DashboardService";
import config from '../../config.json';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';

const Dashboard = () => {
  const defaultFormData: ProductModel = {
    pointOfSale: '',
    product: '',
    id: '0',
    netAdultPrice: 0,
    netChildPrice: 0,
    tax: 0,
    adult: 1,
    child: 0,
    adultPrice: 0,
    childPrice: 0,
    sumPrice: 0,
    exchangeRate: 0,
    sumPriceInEUR: 0,
    subProducts: [],
    selectedDate: '',
    selectedTime: '',
    typeId: '',
    sendId: '',
    preorders: []
  };

  type ValuePiece = Date | null;
  type Value = ValuePiece | [ValuePiece, ValuePiece];
  
  const [formData, setFormData ] = useState(defaultFormData);
  const [cart, setCart] = useState<ProductModel[]>([]);
  const storedUserData: any = localStorage.getItem('userData');
  const [user, setUser] = useState<userData>(JSON.parse(storedUserData) as userData);

  const [hufBtnClass, setHufBtnClass] = useState('btn cart-btn-off');
  const [eurBtnClass, setEurBtnClass] = useState('btn cart-btn-off');
  const [cashBtnClass, setCashBtnClass] = useState('btn cart-btn-off');
  const [cardBtnClass, setCardBtnClass] = useState('btn cart-btn-off');
  const [cashBtnDisabled, setCashBtnDisabled] = useState(true);
  const [cardBtnDisabled, setCardBtnDisabled] = useState(true);

  const [hufBtnDisabled, setHufBtnDisabled] = useState(false);
  const [eurBtnDisabled, setEurBtnDisabled] = useState(false);

  const [currency, setCurrency] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const [closed, setClosed] = useState(false);
  const [closeOrder, setCloseOrder] = useState(false);

  const dashboardService = new DashboardService(user,currency);
  const [productsList, setProductsList] = useState<optionData[]>();
  const [categoryList, setCategoryList] = useState<any[]>();
  const [spacesList, setSpacesList] = useState<any>();
  const [timeList, setTimeList] = useState<any>();
  const [calendarDate, setCalendarDate] = useState<Value>(new Date());
  const [preordersList, setPreordersList] = useState<number[]>();

  
  const rate: exchangeRate = dashboardService.rateData;

  const setProduct = (selectedItem: any) => {
    const defaultSumPrice = selectedItem.adultPrice;
    const eurPrice = Math.round(((defaultSumPrice / rate.rate)*100))/100;
    setSelectedProduct(selectedItem.id);
    const updatedData = { 
      product: selectedItem.value, 
      id: selectedItem.id,
      netAdultPrice: selectedItem.netAdultPrice, 
      netChildPrice: selectedItem.netChildPrice, 
      tax: selectedItem.tax,
      adult: 1, 
      adultPrice: selectedItem.adultPrice, 
      childPrice: selectedItem.childPrice, 
      sumPrice: defaultSumPrice,
      exchangeRate: rate.rate,
      sumPriceInEUR: eurPrice,
      subProducts: selectedItem.subproducts
    };
    setFormData(formData => ({
      ...formData, 
      ...updatedData
    }));
  }

  const setAdult = (qntNumber: number, price: number) => {
    const sumPrice = price * qntNumber;
    const eurPrice = Math.round(((sumPrice / rate.rate)*100))/100;
    const updatedData = { adult: qntNumber, adultPrice: price, sumPrice: sumPrice, exchangeRate: rate.rate, sumPriceInEUR: eurPrice };
    setFormData(formData => ({
      ...formData, 
      ...updatedData
    }));
  }

  const setChild = (qntNumber: number, price: number) => {
    const sumPrice = formData.adultPrice+price;
    const eurPrice = Math.round(((sumPrice / rate.rate)*100))/100;
    const updatedData = { child: qntNumber, childPrice: price, sumPrice: sumPrice, exchangeRate: rate.rate, sumPriceInEUR: eurPrice };
    setFormData(formData => ({
      ...formData, 
      ...updatedData
    }));
  }

  const addProductToCart = () => {
    if (formData.typeId === '5') {
      let prevList = preordersList || [];
      let prodPrevList: number[] = [];
      for (let i = 0; i < formData.adult; i++) {
        dashboardService.prepareInvoice(formData).then((preparedData: any) => {
          const nextID = parseInt(preparedData[0].id);
          prevList.push(nextID);
          prodPrevList.push(nextID);
          setPreordersList(prevList => [...(prevList || []), nextID]);
        });
      }
      let updatedData: ProductModel = formData;
      updatedData.preorders = prodPrevList;
      setFormData(updatedData);
    }

    setCart(current => [...current, formData]);
    setCashBtnDisabled(true);
    setCardBtnDisabled(true);
    setCashBtnClass('btn cart-btn-off');
    setCardBtnClass('btn cart-btn-off');
    setHufBtnClass('btn cart-btn-off');
    setEurBtnClass('btn cart-btn-off');

    defaultFormData.pointOfSale = formData.pointOfSale;
    setFormData(defaultFormData);
    setSelectedProduct('');
    //setCurrency('');
    //setPaymentMethod('');
    setProductsList([]);
    setSelectedTime('');
    setCalendarDate(new Date());
  }

  const updateCart = (cart: any) => {
    setCart(cart);
  }

  const updatePreorders = (preorders: any) => {
    setPreordersList(preorders);
  }

  const logout = () => {
    localStorage.removeItem('userData');
    window.location.reload();
  };

  const setSelectedCurrency = async (selected: string) => {
    setCurrency(selected);
    defaultFormData.pointOfSale = formData.pointOfSale;
    setFormData(defaultFormData);
    await dashboardService.getProductsList(selected, selectedCategory).then((list: any) => {
      setProductsList([...list]);
    });
    setPaymentMethod('');
    if (selected === 'HUF') {
        setHufBtnClass('btn cart-btn-on');
        setEurBtnClass('btn cart-btn-off');
        setCashBtnDisabled(false);
        setCardBtnDisabled(false);
        setCashBtnClass('btn cart-btn-off');
        setCardBtnClass('btn cart-btn-off');
    }
    else if (selected === 'EUR') {
        setEurBtnClass('btn cart-btn-on');
        setHufBtnClass('btn cart-btn-off');
        setCashBtnDisabled(false);
        setCardBtnDisabled(true);
        setCashBtnClass('btn cart-btn-off');
        setCardBtnClass('btn cart-btn-off hideme');
    }
    else {
      setHufBtnClass('btn cart-btn-off');
      setEurBtnClass('btn cart-btn-off');
      setCashBtnDisabled(true);
      setCardBtnDisabled(true);
      setCashBtnClass('btn cart-btn-off');
      setCardBtnClass('btn cart-btn-off');
    }
  }

  const setSelectedPayment = (selected: string) => {
      setPaymentMethod(selected);
      if (selected === 'CASH') {
        setCashBtnClass('btn cart-btn-on');
        setCardBtnClass('btn cart-btn-off');
      }
      else if (selected === 'CARD') {
        setCardBtnClass('btn cart-btn-on');
        setCashBtnClass('btn cart-btn-off');
      }
      else {
        setCashBtnClass('btn cart-btn-off');
        setCardBtnClass('btn cart-btn-off');
      }
  }

  const finishSession = () => {
    setClosed(true);
  }

  const closeOrderMethod = () => {
    setCloseOrder(true);
  }

  const resetForms = () => {
    window.location.reload();
  }

  const selectCategory = async (categoryID: string) => {
    setSelectedCategory(categoryID);
    setSelectedCurrency('');
    setSelectedPayment('');
  };

  useEffect(() => {
    setSelectedTime('');
  },[productsList]);

  useEffect(() => {
    setSelectedTime('');
  },[cart]);

  useEffect(() => {
    setSelectedTime('');
  },[categoryList]);

  useEffect(() => {
    setSelectedTime('');
  },[selectedCategory]);

  useEffect(() => {
  },[spacesList]);

  useEffect(() => {
  },[preordersList]);

  useEffect(() => {
    if (timeList) {
      const updatedData = { selectedTime: selectedTime };
      setFormData(formData => ({
        ...formData, 
        ...updatedData
      }));
    }
  },[selectedTime]);

  useEffect(() => {
  },[selectedCategory]);

  useEffect(() => {
    var tempDate = calendarDate ? calendarDate.toLocaleString() : undefined;
    var formattedDate = tempDate?.substring(0,13);
    formattedDate = formattedDate?.replaceAll(' ', '-');
    formattedDate = formattedDate?.replaceAll('.', '');
    const timelistByDate = spacesList?.filter((spaces: any) => spaces.date === formattedDate);
    setTimeList(timelistByDate);
    setSelectedTime('');
    const sendID = Math.random().toString(20).substr(2, 6);
    const updatedData = { selectedDate: formattedDate, typeId: selectedCategory, sendId: sendID };
    setFormData(formData => ({
      ...formData, 
      ...updatedData
    }));
  },[calendarDate]);

  useEffect(() => {
    if (selectedCategory !== '' && selectedProduct !== '' && currency !== '' && paymentMethod !== '') {
      dashboardService.getAvailableSpaces(parseInt(selectedCategory), parseInt(selectedProduct), '2025-01-16', '2025-03-16', formData.adult).then((response: any) => {
        let spaceItems: any[] = [];
        Object.keys(response).forEach(dateKey => {
          Object.keys(response[dateKey]).forEach(timeKey => {
            spaceItems.push(
              {
                date: dateKey,
                time: timeKey,
                id: response[dateKey][timeKey]['event_id'],
                max: response[dateKey][timeKey]['max_num'],
                participants: response[dateKey][timeKey]['participants']
              }
            );
          });
        });
        setSpacesList(spaceItems);
      });
    }
  },[formData]);

  useEffect(() => {
    dashboardService.getTicketCategoriesList().then((list: any) => {
      const categoryItems = Object.keys(list).map(key => ({
        id: key,
        value: list[key]
      }));
      setCategoryList(categoryItems);
    });
  },[]);

  return (
      <div className="dashboardScreen">
        <Navbar className="navigator">
          <Container className="fullwidth">
            <NavbarBrand href="/">
              <figure className="headerLogo" />
            </NavbarBrand>
            <div className="userProfile">
              <span className="userHello">
                Hello, {user.login}
                <small className="userType">{user.company_name}</small>
              </span>
              <img src="avatar.png" alt={user.login} className="userAvatar" />
              <FontAwesomeIcon className="exitIcon" icon={faSignOut} onClick={logout} title="Logout" />
            </div>
          </Container>
        </Navbar>

      {!closeOrder && 
      <>
        <div className="board">
          <Row>
            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12} xxs={12} className="board-card marged">
              <>
                <label key="zero" className="formLabel zeroLabel">Choose ticket category</label>
                <Row>
                {categoryList?.map((category) => {
                  const btnClass = category.id === selectedCategory ? 'btn cart-btn-on' : 'btn cart-btn-off';
                  return (
                    <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>
                      <button
                        key={'cat_'+category.id}
                        className={`${btnClass}`}
                        onClick={() => selectCategory(category.id)}
                      >
                        {category.value}
                      </button>
                    </Col>
                  );
                })}
                </Row>
                <Row>
                    <Col>
                        <div className="divider"></div>
                    </Col>
                </Row>
              </>
              {selectedCategory !== '' && 
                <>
                  <label key="first" className="formLabel firstLabel">Choose payment type</label>
                  <Row>
                      <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>
                          <button disabled={hufBtnDisabled} className={hufBtnClass} onClick={ () => setSelectedCurrency('HUF') }>HUF</button>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>
                          <button disabled={eurBtnDisabled} className={eurBtnClass} onClick={ () => setSelectedCurrency('EUR') }>EUR</button>
                      </Col>
                  </Row>
                  <Row>
                      <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>
                          <button disabled={cashBtnDisabled} className={cashBtnClass} onClick={ () => setSelectedPayment('CASH') }>CASH</button>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>
                          <button disabled={cardBtnDisabled} className={cardBtnClass} onClick={ () => setSelectedPayment('CARD') }>CARD</button>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <div className="divider"></div>
                      </Col>
                  </Row>
                </>
              }
              {selectedCategory !== '' && currency !== '' && paymentMethod !== '' && 
                <>
                  <Card>
                    <Card.Body>
                      <Card.Title>Choose service</Card.Title>
                      <Card.Text>
                        <Row>
                          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                            <ComboListBox listData={ productsList } id="product" callbackHandler={ setProduct }></ComboListBox>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <br />
                </>
              }
              {formData.product !== '' && 
                <>
                  <Card>
                    <Card.Body>
                      <Card.Title>Add the number of participants</Card.Title>
                      <Card.Text>
                        <Row>
                          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                            <QuantityBox id="adult" label="Adult" subproducts={formData.subProducts} price={formData.adultPrice} currency={currency} tax={formData.tax} defaultValue={ formData.adult } callbackHandler={ setAdult } />
                          </Col>
                        </Row>
                        <Row>
                          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                            <div className="qntSum">
                              {currency === 'EUR' ? formData.sumPrice.toFixed(2) : formData.sumPrice} {currency === 'EUR' ? config.currencySigns.EUR : config.currencySigns.HUF}
                            </div>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <br />
                </>
              }

              {selectedCategory === '5' && currency !== '' && paymentMethod !== '' && selectedProduct !== '' && 
              <>
                <Card>
                  <Card.Body>
                    <Card.Title>Choose one from available dates</Card.Title>
                    <Card.Text>
                      <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                          <Calendar 
                          className={'full-width-calendar'} 
                          onChange={setCalendarDate} 
                          value={calendarDate} 
                          minDate={new Date()} />
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <br />
                <Card>
                  <Card.Body>
                    <Card.Title>Choose one from available times</Card.Title>
                    <Card.Text>
                      <Row>
                        {(timeList === undefined || timeList === null || timeList.length === 0) && 
                          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                            <Alert key={'noTime'} variant={'secondary'}>No available item...</Alert>
                          </Col>
                        }
                        {timeList?.map((timeItem: any) => {
                          return (
                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>
                              <input type="radio" name="selectedTime" value={timeItem.id} onChange={(e) => setSelectedTime(e.target.value)} /> {timeItem.time}
                              <small className="max-persons">(Available places: {timeItem.max - timeItem.participants} / {timeItem.max})</small>
                            </Col>
                          );
                        })}
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </>
              }

              {formData.product !== '' && formData.sumPrice > 0 && ((selectedCategory === '5' && selectedTime !== '') || (selectedCategory !== '5')) && 
                <>
                  <button className="btn cart-btn" onClick={addProductToCart}>Add to cart</button>
                  <br /><br />
                </>
              }

              { closed && 
                <>
                  <div className="disabled-panel"></div>
                </>
              }
            </Col>

            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12} xxs={12} className="board-card marged">
              <Cart cart={ cart } preordersList={ preordersList } currency={currency} callbackHandler={ updateCart } updatePreordersList={ updatePreorders } />
            </Col>

            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12} xxs={12} className="board-card">
              <Order cart={ cart } preorders={ preordersList } currency={ currency } paymentMethod={ paymentMethod } callbackHandler={ finishSession } closeSession={ closeOrderMethod } />
            </Col>
          </Row>
        </div>
      </>
      }

      {closeOrder && paymentMethod !== 'CARD' &&
      <>
        <div className="board">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
              <div className="close-container">
                <h3 className="centerizedText">
                  Thank you for your purchase!
                </h3>
                <button type="button" className="bigButton" onClick={ () => resetForms() }>
                  <FontAwesomeIcon className="homeIcon" icon={faHome} title="Goto home" />
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </>
      }

      {closeOrder && paymentMethod === 'CARD' &&
      <>
        <div className="board">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
              <div className="close-container">
                <h3 className="centerizedText">
                  Thank you for your purchase!
                </h3>
                <h4>The payment link required to finalize the purchase and it was sent by letter to the specified email address.</h4><br />
                <button type="button" className="bigButton" onClick={ () => resetForms() }>
                  <FontAwesomeIcon className="homeIcon" icon={faHome} title="Goto home" />
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </>
      }

      </div>
  );
}

export default Dashboard;
